import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { lastValueFrom, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GetQuery } from 'src/lib/generics';
import { SpinnerService } from '../services/spinner.service';
import { ThalosApiService } from '../services/thalos-api.service';

@Injectable()
export class EntityContainerResolver {
  constructor(
    private api: ThalosApiService,
    private router: Router,
    private spinnerService: SpinnerService,
  ) {}

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let endpoint = route.data['endpoint'];

    if (!endpoint) return null;

    let idFields: string[] = route.data['idFields'] || ['id'];
    let entityLoader: (any, api: ThalosApiService) => Observable<any> = route.data['entityLoader'];
    let filters: any = {};
    for (let idField of idFields) {
      let idValue = route.params[idField];
      if (!isNaN(idValue)) idValue = parseInt(idValue);
      if (!!idValue || idValue === 0) filters[idField] = idValue;
      else return null;
    }

    let query: GetQuery<any> = { filters };

    let relations = route.data['relations'];
    if (!!relations) {
      query.relations = relations;
    }

    let rId = this.spinnerService.startRequest();
    let res = await lastValueFrom(
      this.api.rpc<any>(endpoint, query, null, { hideErrorPopup: true }).pipe(
        switchMap((val) => {
          if (!!entityLoader && !!val) {
            return entityLoader(val, this.api);
          } else return of(val);
        }),
      ),
    );
    this.spinnerService.completeRequest(rId);

    if (!res) {
      this.router.navigate(['error/404']);
      return null;
    }

    return res || null;
  }
}
